<template>
  <component :is="tag" class="list-with-title">
    <div class="section-inner">
      <h2 v-if="title.length" class="main-title size-l">{{ title }}</h2>
      <ul class="list">
        <li v-for="item in list" :key="item.uid" class="list-item">
          <NuxtLink class="list-link" :to="strippedURL(item.link_url)">
            {{ item.link_label }}</NuxtLink
          >
        </li>
      </ul>
    </div>
  </component>
</template>

<script setup>
const config = useRuntimeConfig();
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});
const { blok } = toRefs(props);
const title = blok.value.title;
const list = blok.value.body[0]?.items;

const countryPrefix = config.public["storyblokCountryPrefix"];
</script>

<style lang="scss" scoped>
.list-with-title {
  .section-inner {
    background-color: var(--solid-07);
    border-radius: $radius-l;
    padding: 2.5rem 1.125rem;
    @include for-desktop-up {
      padding: 3rem 4.5rem;
    }
  }
  .list {
    display: grid;
    margin-top: 1rem;
    grid-gap: 0.5rem;
    @include for-desktop-up {
      grid-template-columns: auto auto auto auto;
      margin-top: 2rem;
      grid-gap: 0.75rem;
    }
  }
  & + .list-with-title {
    margin-top: 1.5rem;
    @include for-desktop-up {
      margin-top: 2.5rem;
    }
  }
}
</style>
